<template>
  <div>
    <div class="form-group">
      <input ref="inputElement" type="text" class="form-control icon-right" v-model="searchWord" aria-label="Suchbegriff eingeben" placeholder="Arama" v-on:keyup.enter="onEnter" @keypress.enter.prevent="">
      <span class="material-icons right search-icon" aria-hidden="true" @click="onEnter" @keyup.enter="onEnter" tabindex="0" data-wg-notranslate="">search</span>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'KeywordSearch',
    props: {
      initialValue: {
        type: String,
      default: ""
      },
      focusMe: {
        type: Boolean,
      default: false,
      },
    },
    data() {
      return {
        searchWord: '',
        awaitingSearch: false,
      }
    },
    watch: {
      initialValue(newWord){
        this.searchWord = newWord;
      },
      /*searchWord(newWord, oldWord){
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.$emit('searchWordChanged',this.searchWord);
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      }, */
    },
    mounted() {
      /*set the time to the initialValue*/
      this.searchWord = this.initialValue;
      if(this.focusMe){
        this.$refs.inputElement.focus();
      }
    },
    methods: {
      onEnter(){
        this.$refs.inputElement.blur();
        this.$emit('searchWordChanged',this.searchWord);
      },
      focus(){
        this.$refs.inputElement.focus();
      },
    }
  }
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .search-icon {
    cursor: pointer;
    color: $primary;
    background-color: #cee7f0;

    &:hover {
      background-color: #b5ddeb;
    }
  }

  .filter-bottom {
    padding-right: 0;
  }

</style>
